import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import Text from "../components/text/text"

const FamilyPage = () => (
	<Layout>
		<div className="heading-wrap">
			<div className="heading">

				<div className="heading-text-wrap">
					<div className="heading-text">
						<center>
							<h1>Сім’я</h1>
						</center>
					</div>
				</div>
			</div>

			<Text>
				<p>
					Наша основна направленість — розвиток емоційного інтелекту дітей.<br />
					Наша основна задача, ціль і мета — щоденно допомагати дитині ставати такою, яка здатна гнучко мислити, аналізувати і критично осмислювати навколишню дійсність.<br />
					Зростати особистістю, яка сповнена любові, віри і поваги до себе та інших. Яка є здоровою і має напрацьовані кейси з компетентностей у різних діяльностях. Яка є щасливою і стійкою до викликів життя.
				</p>

				<h2>Наші цінності</h2>

				<p>
					Цінності Lady&Baby є способом реалізації наших базових засадничих переконань та світоглядної позиції щодо зростання дитини.
				</p>

				<ol>
					<li>Ми підтримуємо розвиток емоційного інтелекту дитини, контейнерування її почуттів і розвиток рефлексії.</li>
					<li>Тонкочуттєвість і емпатія — як стиль батьківства і Педагогіки Lady&Baby.</li>
					<li>Ми збалансовуємо освітнє навантаження для дитини.</li>
					<li>Розвиток дитини зароджується в стані спокою.</li>
					<li>Ми підтримуємо розвиток самостійності та навичок самообслуговування — вдягання/роздягання, гігієнічний догляд за собою, споживання їжі, тощо.</li>
					<li>Педагогиня нашої команди — є надійним, стабільним, підтримуючим об’єктом для дитини.</li>
					<li>Прозорість і спрямованість на прояснення, уточнення і узгодження різних життєвих ситуацій у садочку.</li>
					<li>Саморозвиток і професійний розвиток — це стиль життя команди Lady&Baby.</li>
					<li>Простір Lady&Baby — це про безпеку, баланс, довіру, прийняття, усмішки, розваги і розвиток.</li>
				</ol>

				<h2>Lady&Baby у цифрах</h2>

				<p>
					2 співзасновниці Марія Лєбєдєва та Юлія Герасимяк<br />
					6 років практичної діяльності у сфері дошкільної освіти<br />
					2 дитячих садочки біля ЖК «Софія» та ЖК «Софія Резіденс»<br />
					1 сімейний центр Lady&Baby LM в ЖК «Львівський Маєток»<br />
					<strong>10 000+</strong> маленьких випускників комплексних занять з раннього розвитку
				</p>

				<h2>Чому обирають саме нас</h2>

				<ol>
					<li>Розвиток емоційного інтелекту. Підтримкою розвитку емоційного інтелекту пронизана кожна діяльність і активність у нашому садочку.</li>
					<li>Навчальна програма для команди Lady&Baby:</li>
						<ul>
							<li>вивчення дитячого психоаналізу, що також включає щотижневі Читальні з психотерапевтинею, присвячені осмисленню та обговоренню профільної літератури. Зокрема психоаналітичні джерела про етапи і особливості розвитку дитини;</li>
							<li>створення і підтримка контейнеруючого простору для команди Lady&Baby;</li>
							<li>індивідуальна робота з випадками, які потребують додаткової уваги;</li>
							<li>лекції і тренінги присвячені розвитку професійних компетенцій команди Lady&Baby;</li>
							<li>регулярні курси для команди з надання першої медичної допомоги.</li>
						</ul>
					<li>Сучасність у підходах до реалізації щоденного життя садочка. В нашому розумінні справа не лише в стінах (хоча це також важливо), а і в тому як дитина себе почуває всередині них.</li>
				</ol>
			</Text>

		</div>
	</Layout>
)

export const Head = () => <Seo title="Сім’я" />

export default FamilyPage
