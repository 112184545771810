import * as React from "react"

import "./text.scss"

const Text = (props) => {
    return (
        <>
            <div className="text-wrap">
                <div className="text">
                    {props.children}
                </div>
            </div>
        </>
    );
};

export default Text
